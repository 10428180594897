import Logo from './Logo';
import alien from '../../assets/images/alien_small.png';

/**
 * ModalLogo
 * This component is used for modal menu
 */
export function ModalLogo() {
  return (
    <div className="logo-container logo-container--center">
      <img alt="Alien CryptoPunk" src={alien} />
    </div>
  );
}

/**
 * LeftLogo
 * This component is used for main pages
 */
export function LeftLogo() {
  return (
    <div className="logo-container logo-container--header">
      <Logo size="medium" />
    </div>
  );
}

/**
 * CenterLogo
 * This component is used for splash page
 */
export function CenterLogo() {
  return (
    <div className="logo-container logo-container--center">
      <Logo size="large" />
    </div>
  );
}
