import {Link} from 'react-router-dom';

import Nav from './Nav';
import alien from '../assets/images/alien_small.png';

export default function Header() {
  /**
   * Functions
   */

  // Render the location with a link
  function RenderLogo(props: React.PropsWithChildren<any>) {
    return <Link to="/">{props.children}</Link>;
  }

  /**
   * Render
   */

  return (
    <header className="header">
      <RenderLogo>
        <img
          className="header__logo-image"
          alt="Alien CryptoPunk"
          src={alien}
          data-testid="header-alien"
        />
      </RenderLogo>

      <div className="header__nav-container">
        <Nav />
      </div>
    </header>
  );
}
