import {config as dotenvConfig} from 'dotenv';
import {IProviderOptions} from 'web3modal';
import {isMobile} from '@walletconnect/browser-utils';
import {resolve} from 'path';
import WalletConnectProvider from '@walletconnect/web3-provider';

import {EnvironmentName} from './util/types';

dotenvConfig({path: resolve(__dirname, '../.env')});

/**
 * Global DApp Config
 */

const {
  REACT_APP_DAO_REGISTRY_CONTRACT_ADDRESS,
  REACT_APP_DEFAULT_CHAIN_NAME_LOCAL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_GRAPH_API_URL,
  REACT_APP_INFURA_PROJECT_ID_DEV,
  REACT_APP_INFURA_PROJECT_ID_LOCAL,
  REACT_APP_INFURA_PROJECT_ID_PROD,
  REACT_APP_MULTICALL_CONTRACT_ADDRESS,
  REACT_APP_SNAPSHOT_HUB_API_URL,
  REACT_APP_COUPON_API_URL,
  REACT_APP_SNAPSHOT_SPACE,
} = process.env;

export const ENVIRONMENT = REACT_APP_ENVIRONMENT as EnvironmentName | undefined;

/**
 * SNAPSHOT_HUB_API_URL
 *
 * @note For `ENVIRONMENT=localhost` we need to use CRA's local proxy
 *   so that we can communicate with our develop Snapshot Hub API
 *   without any CORS issues.
 *
 * @see src/setupProxy.js
 */
export const SNAPSHOT_HUB_API_URL: string | undefined =
  ENVIRONMENT === 'localhost'
    ? '/snapshot-hub'
    : REACT_APP_SNAPSHOT_HUB_API_URL;

export const COUPON_API_URL: string | undefined = REACT_APP_COUPON_API_URL;

// The Graph API URL
export const GRAPH_API_URL = REACT_APP_GRAPH_API_URL;

// Network IDs, when users change wallet networks
export const CHAINS = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  GANACHE: 1337,
  HARMONY_TEST: 1666700000,
  HARMONY_MAIN: 1666600000,
  POLYGON_TEST: 80001,
  POLYGON: 137,
} as const;

// Network names
export const CHAIN_NAME = {
  [CHAINS.MAINNET]: 'mainnet',
  [CHAINS.ROPSTEN]: 'ropsten',
  [CHAINS.RINKEBY]: 'rinkeby',
  [CHAINS.GOERLI]: 'goerli',
  [CHAINS.KOVAN]: 'kovan',
  [CHAINS.GANACHE]: 'ganache',
  [CHAINS.HARMONY_TEST]: 'harmony-testnet',
  [CHAINS.HARMONY_MAIN]: 'harmony-mainnet',
  [CHAINS.POLYGON]: 'polygon-mainnet',
  [CHAINS.POLYGON_TEST]: 'polygon-testnet',
} as const;

// Network names verbose
export const CHAIN_NAME_FULL = {
  [CHAINS.MAINNET]: 'Main Ethereum Network',
  [CHAINS.ROPSTEN]: 'Ropsten Test Network',
  [CHAINS.RINKEBY]: 'Rinkeby Test Network',
  [CHAINS.GOERLI]: 'Görli Test Network',
  [CHAINS.KOVAN]: 'Kovan Test Network',
  [CHAINS.GANACHE]: 'Ganache Test Network',
  [CHAINS.HARMONY_TEST]: 'Harmony Test Network',
  [CHAINS.HARMONY_MAIN]: 'Harmony Main Network',
  [CHAINS.POLYGON_TEST]: 'Polygon Test Network',
  [CHAINS.POLYGON]: 'Polygon Main Network',
};

export const DEFAULT_CHAIN: typeof CHAINS[keyof typeof CHAINS] =
  REACT_APP_ENVIRONMENT === 'production'
    ? CHAINS.MAINNET
    : REACT_APP_ENVIRONMENT === 'development'
    ? CHAINS.RINKEBY
    : REACT_APP_DEFAULT_CHAIN_NAME_LOCAL // Set this to change local development chain
    ? CHAINS[REACT_APP_DEFAULT_CHAIN_NAME_LOCAL]
    : CHAINS.GANACHE; // Defaults to a Ganache private network (1337)

export const ETHERSCAN_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: `https://etherscan.io`,
  [CHAINS.ROPSTEN]: `https://ropsten.etherscan.io`,
  [CHAINS.RINKEBY]: `https://rinkeby.etherscan.io`,
  [CHAINS.GOERLI]: `https://goerli.etherscan.io`,
  [CHAINS.KOVAN]: `https://kovan.etherscan.io`,
  [CHAINS.HARMONY_TEST]: `https://explorer.pops.one`,
  [CHAINS.HARMONY_MAIN]: `https://explorer.harmony.one`,
  [CHAINS.POLYGON_TEST]: `https://mumbai.polygonscan.com`,
  [CHAINS.POLYGON]: `https://polygonscan.com`,
};

export const INFURA_WS_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: `wss://mainnet.infura.io/ws/v3`,
  [CHAINS.ROPSTEN]: `wss://ropsten.infura.io/ws/v3`,
  [CHAINS.RINKEBY]: `wss://rinkeby.infura.io/ws/v3`,
  [CHAINS.GOERLI]: `wss://goerli.infura.io/ws/v3`,
  [CHAINS.KOVAN]: `wss://kovan.infura.io/ws/v3`,
  [CHAINS.HARMONY_TEST]: `wss://ws.s0.pops.one`,
  [CHAINS.HARMONY_MAIN]: `wss://ws.s0.t.hmny.io`,
  [CHAINS.POLYGON_TEST]: `wss://ws-matic-mumbai.chainstacklabs.com`,
  [CHAINS.POLYGON]: `wss://ws-matic-mainnet.chainstacklabs.com`,
};

// Infura Project Id
export const INFURA_PROJECT_ID =
  REACT_APP_ENVIRONMENT === 'production'
    ? REACT_APP_INFURA_PROJECT_ID_PROD
    : REACT_APP_ENVIRONMENT === 'development'
    ? REACT_APP_INFURA_PROJECT_ID_DEV
    : REACT_APP_INFURA_PROJECT_ID_LOCAL;

// Ethereum Provider URL
export const ETHEREUM_PROVIDER_URL: string = INFURA_WS_URLS[DEFAULT_CHAIN]
  ? `${INFURA_WS_URLS[DEFAULT_CHAIN]}/${INFURA_PROJECT_ID}`
  : DEFAULT_CHAIN === CHAINS.GANACHE
  ? /**
     * Ganache over WebSocket should work. @note Is not tested, yet.
     * Attempting to be consistent with a WebSocket URL to avoid more logic.
     *
     * @link https://www.trufflesuite.com/docs/truffle/reference/configuration#networks
     */
    'ws://127.0.0.1:7545'
  : '';

/**
 * Wallet Connect config
 */

/**
 * Tell Web3modal what providers we have available.
 *
 * The built-in web browser provider (only one can exist at a time),
 * MetaMask, Brave or Opera is added automatically by Web3modal
 */
export const WALLETCONNECT_PROVIDER_OPTIONS: IProviderOptions = {
  // Injected providers
  injected: {
    display: {
      name: 'MetaMask',
      description: 'Connect with the provider in your Browser',
    },
    package: null,
  },
  // WalletConnect provider
  walletconnect: {
    display: {
      name: 'WalletConnect',
      description: 'Connect with your mobile wallet',
    },
    package: WalletConnectProvider,
    options: {
      infuraId: INFURA_PROJECT_ID, // required
      qrcodeModalOptions: {
        mobileLinks: isMobile()
          ? ['rainbow', 'metamask', 'argent', 'trust']
          : [],
      },
    },
  },
};

/**
 * CORE CONTRACTS
 * @note as per https://github.com/openlawteam/tribute-contracts#architecture
 *
 * - DAO Registry (@note uses dao address for the contract address)
 * - DAO Factory
 */

// If developing locally, include your DaoRegistry contract address in your `.env` file.
export const DAO_REGISTRY_CONTRACT_ADDRESS =
  REACT_APP_DAO_REGISTRY_CONTRACT_ADDRESS;

export const DAO_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x9a1Bae2B9C69eE18D44792993cA7a0B4DA353038',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x6d92a8E4aB80adcBbFDA44ef69fe847f82def641',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const BANK_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x50b6FB1609b5a1ed70618d25346D87e8c81726ee',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xcD1639FD072113CC86e15f65f1505C6D58Aa7412',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

// @todo
export const NFT_COLLECTION_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x942F52C78914e91DCAeFfa72ef592e1850fd6B2F',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xcF34FFFBba648719DAe106202674949f1679772B',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

// @todo
export const ERC20_TOKEN_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xcB7e9F48795940F16f11FBE9F1fb0C4d1a07ca6D',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x862d71d6E9Be8a7495EfEBa6f2b657b00E629c95',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

/**
 * ADAPTER CONTRACTS
 * @note as per https://github.com/openlawteam/tribute-contracts#architecture
 *
 * - Configuration
 * - CouponOnboardingContract
 * - Managing
 * - Onboarding
 * - Voting
 * - Offchain voting
 * - Financing
 * - Tribute
 * - Distribute
 * - Rage quit
 * - Guild kick
 * - BankAdapter
 * - TributeNFT
 * - NFTAdapter
 * - DaoRegistryAdapter
 * - KycOnboarding
 */

export const VOTING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x163A402FEF377884d56b662B9E9218E6c24Eb35B',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xfB4E7C2FD8Ceb2757D2C4cE4749ebE326fc369D4',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const CONFIGURATION_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xbaa8B589eb7e331c62426564C3f548e7a159AeFc',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x65CaCA9F2638Fb2B245E9694f00343F0b6CB6F77',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const RAGEQUIT_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x79a62acE2FE260562212a2fC38c0F31140320d28',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x314C824Ea4586798DF8B46121E881Defa40CB47E',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const MANAGING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x60CA95776A193f5936C032a8CF7E9Bd549B38ddF',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xeD025b5BA14b8f3A085057b9D7Baa13998343aA8',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const FINANCING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x59DccFbe3782EE621Ad3B72270dF8A6E17BccA02',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x4a7A5946567672c577EaF2b866BCeBc177E455E8',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const ONBOARDING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xc7bf281CF2b5D3f42E0AA72440b2F4C92E798f9A',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x4e1Ccf6Bca8d14B670478d8C4e2aEc9f6277A7f5',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const GUILDKICK_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xF84857ed3F2D8661a81ce7226D0942Aa4BDeBFC5',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x72a011Aa67F8214a146A162A99B322017ad763fD',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const DAO_REGISTRY_ADAPTER_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x7e70c85657DC6fe60724ebac8DCC6632d534F520',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x4d4243f2d605282CBd27C8391f05b77316d1a561',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const BANK_ADAPTER_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x4bD41497C2876D243Ac4D13e0C8991A4d9aDC586',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xA8EA078cF1D3837a025329c46A4E0F441Cb2eE87',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const NFT_ADAPTER_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xa1AE75B26bB1C35B7Bd3b57D950708DFD25Ab932',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x538a4f00d64d2597717cAAd4D01C963317e3Ae40',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const COUPONONBOARDING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x721512Fecc9440012ab388E367f8A6237D46Bb45',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x9A2E533FBa58Ec8b0dA34438154C48E295C80576',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const TRIBUTE_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x5eA1E81a6FB76ccD8F7a7A444d92bDB48897F5e8',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xa7D723BFc4deC341da03E70Af4026B3926b1E8Db',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const DISTRIBUTE_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x9224E3d57d3640dB691fd337DCf69677f81Ca0f8',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x6749b9151B654A77ddA3Fa8f2b259E0E592065F6',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const TRIBUTE_NFT_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x257da6C9625e384a7B7938AB8DeBfdC37054c757',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x4Df3729e7B952EBE6d18b52132D2A8af2F2D167A',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const OFFCHAINVOTING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x8009bB8A914Ea6412001915cF5358562E0203225',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xae02a075290D2efD74F0D3fE2DfE64831320855a',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const KYC_ONBOARDING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

// If developing locally, include your Multicall contract address in your `.env` file.
export const MULTICALL_CONTRACT_ADDRESS = REACT_APP_MULTICALL_CONTRACT_ADDRESS;

/**
 * These addresses are important as the contracts use them in their configs.
 *
 * @todo Remove and get from the chain/subgraph?
 *
 * @see https://github.com/openlawteam/tribute-contracts/blob/9e0e03616a00e41e666351e146ee109b9fe37fb2/utils/DaoFactory.js
 */
export const GUILD_ADDRESS: string =
  '0x000000000000000000000000000000000000dead';
export const TOTAL_ADDRESS: string =
  '0x000000000000000000000000000000000000babe';
export const UNITS_ADDRESS: string =
  '0x00000000000000000000000000000000000FF1CE';
export const LOOT_ADDRESS: string =
  '0x00000000000000000000000000000000B105F00D';
export const MEMBER_COUNT_ADDRESS: string =
  '0x00000000000000000000000000000000DECAFBAD';
export const ETH_TOKEN_ADDRESS: string =
  '0x0000000000000000000000000000000000000000';
export const DAI_TOKEN_ADDRESS: string =
  '0x95b58a6bff3d14b7db2f5cb5f0ad413dc2940658';

/**
 * `SPACE` is used inside Snapshot Hub for matching a `space`
 * with its own proposals and votes.
 */
export const SPACE: string | undefined = REACT_APP_SNAPSHOT_SPACE;

/**
 * POLLING INTERVAL FOR GQL QUERIES
 * localhost | development - ms, poll every 5sec = 5000
 * production - ms, poll every 10sec = 10000
 */
export const GQL_QUERY_POLLING_INTERVAL: number =
  REACT_APP_ENVIRONMENT === 'production' ? 10000 : 5000;

export const DOCS_URL: string =
  'https://medium.com/@FLAMINGODAO/hi-everyone-alien-here-i-bring-a-dao-6b472439d5a6';

export const HELP_EMAIL: string = 'help@aliendao.xyz';
