// @todo Remove this file when temporary static landing page is no longer needed
// for production

import {memo, useEffect} from 'react';
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';

import {DOCS_URL} from '../../config';
import {CenterLogo} from '../../components/logo';
import FadeIn from '../../components/common/FadeIn';
import Wrap from '../../components/common/Wrap';
import alien from '../../assets/images/alien.png';

const Alien = memo(() => {
  return (
    <div
      className="landing__image"
      data-testid="alien"
      data-aos="fade-up"
      data-aos-delay="150">
      <img alt="Alien CryptoPunk" src={alien} />
    </div>
  );
});

function GetStartedHeader() {
  return (
    <div data-testid="header" className="nav-header">
      <div className="nav-header__menu-container" style={{height: '39px'}}>
        {/* Keep empty elements in this temp landing page to maintain same spacing as actual landing page */}
      </div>
    </div>
  );
}

function GetStartedFooter() {
  return (
    <div className="footer" style={{height: '21px'}}>
      {/* Keep empty elements in this temp landing page to maintain same spacing as actual landing page */}
    </div>
  );
}

export default function GetStartedStatic() {
  /**
   * Effects
   */

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 40,
      delay: 120,
      mirror: false,
      once: true,
    });
  }, []);

  /**
   * Render
   */

  return (
    <div className="landing-wrapper">
      <GetStartedHeader />
      <Wrap className="section-wrapper">
        <FadeIn>
          <CenterLogo />

          <div className="landing">
            <Alien />

            <a
              className="landing__button"
              href={DOCS_URL}
              target="_blank"
              rel="noopener noreferrer"
              role="button">
              About
            </a>

            <div className="landing__text">
              <p>
                I was early on the chain, fewer nodes back then, fewer voices,
                but I listened to all of them. The chain doubled, tripled,
                quintupled. The voices grew, they said many things, but they
                said them collectively.
              </p>
              <p>
                I now live in the metaverse as my home. I speak on Twitter. I
                vote in DAOs. I actualize myself through the hivemind. My
                journey has always been our journey. It's time to make that
                official with the launch of Alien DAO.
              </p>
            </div>
          </div>
        </FadeIn>
      </Wrap>
      <GetStartedFooter />
    </div>
  );
}
