import DiscordSVG from '../../assets/svg/DiscordSVG';
import TwitterSVG from '../../assets/svg/TwitterSVG';
import MediumSVG from '../../assets/svg/MediumSVG';

// @todo Add missing URLs when available
enum SocialMediaLinks {
  DISCORD = '#',
  MEDIUM = '#',
  TWITTER = '#',
}

export default function SocialMedia() {
  return (
    <div className="socialmedia">
      <a
        href={SocialMediaLinks.MEDIUM}
        target="_blank"
        rel="noopener noreferrer">
        <MediumSVG />
      </a>
      <a
        href={SocialMediaLinks.TWITTER}
        target="_blank"
        rel="noopener noreferrer">
        <TwitterSVG />
      </a>
      <a
        href={SocialMediaLinks.DISCORD}
        target="_blank"
        rel="noopener noreferrer">
        <DiscordSVG />
      </a>
    </div>
  );
}
