import {Helmet} from 'react-helmet';

export default function Head() {
  return (
    <Helmet>
      <title>Alien DAO</title>
      <meta
        name="description"
        content="A creative DAO bringing the first native citizen of Ethereum to life"
      />
    </Helmet>
  );
}
