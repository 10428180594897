import {Route, Switch} from 'react-router-dom';

// import AdapterOrExtensionManager from './components/adapters-extensions/AdapterOrExtensionManager';
import CreateGovernanceProposal from './pages/governance/CreateGovernanceProposal';
import GetStarted from './pages/start/GetStarted';
import GovernanceProposalDetails from './pages/governance/GovernanceProposalDetails';
import GovernanceProposals from './pages/governance/GovernanceProposals';
import Members from './pages/members/Members';
import MemberProfile from './pages/members/MemberProfile';
import NotFound from './pages/subpages/NotFound';
import Redeem from './pages/redeem/Redeem';
// @todo Remove these imports when temporary static landing page is longer needed
import {ENVIRONMENT} from './config';
import GetStartedStatic from './pages/start/GetStartedStatic';

const proposalIdParameter: string = ':proposalId';

export default function Routes() {
  // @todo Remove these routes when temporary static landing page is no longer
  // needed for production
  if (ENVIRONMENT === 'production') {
    return (
      <Switch>
        {[
          <Route
            key="splash"
            exact
            path="/"
            render={() => <GetStartedStatic />}
          />,
          <Route key="no-match" component={NotFound} />,
        ]}
      </Switch>
    );
  }

  return (
    <Switch>
      {[
        // Index page
        <Route key="splash" exact path="/" render={() => <GetStarted />} />,
        <Route
          key="governance-proposal"
          exact
          path="/governance-proposal"
          render={() => <CreateGovernanceProposal />}
        />,
        <Route
          key="governance-proposals"
          exact
          path="/governance"
          render={() => <GovernanceProposals />}
        />,
        <Route
          key="governance-proposal-details"
          exact
          path={`/governance/${proposalIdParameter}`}
          render={() => <GovernanceProposalDetails />}
        />,
        <Route
          key="members"
          exact
          path="/members"
          render={() => <Members />}
        />,
        <Route
          key="member-profile"
          exact
          path="/members/:ethereumAddress"
          render={() => <MemberProfile />}
        />,
        // @note Disabling DAO Manager for now because we paused on maintaining
        // it.
        // <Route
        //   key="dao-manager"
        //   exact
        //   path="/dao-manager"
        //   render={() => <AdapterOrExtensionManager />}
        // />,
        <Route key="redeem" exact path="/redeem" render={() => <Redeem />} />,
        // 404 component (note: does not redirect to a route to maintain original path)
        <Route key="no-match" component={NotFound} />,
      ]}
    </Switch>
  );
}
